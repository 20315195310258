<!--  -->
<template>
  <div class=''>
    <div style="font-size: 28px;">我的成员管理</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <!-- <el-input style="width: 500px" placeholder="请输入内容" v-model="search" clearable>
    </el-input> -->

    <el-table :data="tableData"
      style="width: 100%" border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}">

      <el-table-column type="index" width="40"></el-table-column>
      <el-table-column label="手机号" prop="phone" width="150"></el-table-column>

      <el-table-column label="用户姓名" :span="6">
        <template slot-scope="scope">
          <el-col :span="18">
            <el-input
              :rows="1"
              placeholder="请输入内容"
              v-model="scope.row.realName"  >
            </el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-button @click="updateRealName(scope.row)" type="primary">保存</el-button>
          </el-col>
        </template>
      </el-table-column>

      <!-- <el-table-column label="个人信息">
        <template slot-scope="scope">
          <el-button @click="psInfo(scope.row)" type="primary">{{ '个人信息表' }}</el-button>
        </template>
      </el-table-column> -->

      <el-table-column label="检测记录查询" width="180">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <el-button @click="getECG(scope3.row.uid)">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope3.row.uid)">
              动态心电
            </el-button>
            <el-button @click="getBloodPressure(scope3.row.uid)">
              血压
            </el-button>
            <el-button @click="getBloodOxygen(scope3.row.uid)">
              血氧
            </el-button>
            <el-button @click="getBodyTemprature(scope3.row.uid)">
              体温
            </el-button>
            <el-button @click="getThreeInOne(scope3.row.uid)">
              三合一
            </el-button>
            <el-button @click="getBodyFat(scope3.row.uid)">
              体脂
            </el-button>
            <el-button @click="getUrine(scope3.row.uid)">
              尿检
            </el-button>
            <el-button @click="get8ECG(scope3.row.uid)">
              8导联静态
            </el-button>
            <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联holter
            </el-button>
            <el-button slot="reference" type="info">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="备注" :span="6">
        <template slot-scope="scope">
          <el-col :span="18">
            <el-input
              :rows="1"
              placeholder="请输入内容"
              v-model="scope.row.remarks"  >
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="updateRemark(scope.row)" type="primary" >保存</el-button>
          </el-col>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180">
        <template v-slot:default="scope4">
          <el-button type="danger" @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <!-- <el-dialog :visible.sync="psInfoVisible">
      <ps_information/>
    </el-dialog> -->
    <div style="padding-top: 40px">
      <div style="font-size: 24px;padding-bottom: 10px">添加新用户</div>
      <el-col :span="6">
        <el-input
          :rows="1"
          placeholder="请输入手机号或用户名"
          v-model="userNameOrPhone"  >
        </el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button @click="addNewUser" type="primary" >添加</el-button>
      </el-col>
    </div>
  </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import ps_information from '../../personal_module/module3/ps_information.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    // ps_information
  },
  data() {
    //这里存放数据
    return {
      size: '',
      // psInfoVisible: false,
      tableData: [
      ],
      userNameOrPhone: ''
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
        get() { return this.$store.state.module.crossPlatform },
        set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    handleDelete(index, row) {
      console.log("看看删除啥",index, row);
      console.log(row.uid, row.reid, this.$store.state.doctor_station.docid);
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/deleteUser"),
            method: "post",
            data: this.$http.adornData({
              docId: this.$store.state.doctor_station.docid,
              uid: row.uid,
              reid: row.reid
            }),
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getUserList()
            } else {
              console.log(data);
              this.$message({
                type: 'info',
                message: data.msg
              });     
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },

    // 获取列表
    getUserList(){

      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/list"),
        method: "post",
        data: this.$http.adornData({
          docId: this.$store.state.doctor_station.docid
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
          this.tableData = data.relationEntities
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },

    // 检测项目跳转
    getECG(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistorySingoJump',
        query: {
          uid: uid,
        } })
    },
    getECGHolter(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        } })
    },

    getBloodFat(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryBloodFatJump',
        query: {
          uid: uid,
        } })
    },
    getBodyGlucose(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryBodyGlucoseJump',
        query: {
          uid: uid,
        } })
    },
    getBloodOxygen(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryBloodOxygenJump',
        query: {
          uid: uid,
        } })
    },
    getBloodPressure(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryBloodPressureJump',
        query: {
          uid: uid,
        } })
    },
    getBaby(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryBabyJump',
        query: {
          uid: uid,
        } })
    },
    getBio(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryBioJump',
        query: {
          uid: uid,
        } })
    },
    getBodyTemprature(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryTemJump',
        query: {
          uid: uid,
        } })
    },
    getBodyFat(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryEightJump',
        query: {
          uid: uid,
        } })
    },
    getThreeInOne(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryThreeInOneJump',
        query: {
          uid: uid,
        } })
    },
    getUrine(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryUrineJump',
        query: {
          uid: uid,
        } })
    },
    get8ECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightHolterJump',
        query: {
          uid: e,
        }
      })
    },
    addNewUser(){
      if (this.userNameOrPhone == ""){
        this.$alert('未填写用户信息', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }else {
        console.log("看看docId", this.$store.state.doctor_station.docid, this.userNameOrPhone);
        console.log(typeof(this.$store.state.doctor_station.docid));
        let that = this
        this.$http({
          url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/addUser"),
          method: "post",
          data: this.$http.adornData({
            userPhone: that.userNameOrPhone,
            docId: this.$store.state.doctor_station.docid
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.userNameOrPhone = ""
            this.getUserList()
          } else {
            console.log(data);
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    updateRealName(e){
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateUserRealName"),
        method: "post",
        data: this.$http.adornData({
          uid: e.uid,
          realName: e.realName
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    updateRemark(e){
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateRemarks"),
        method: "post",
        data: this.$http.adornData({
          reid: e.reid,
          remarks: e.remarks
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getUserList()
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.drawerTxt {
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}

.el-table .warning-row {
  background: oldlace;
}
</style>
    